














































































































































































































































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import {
  GetLeftCategory,
  GetShitiList,
  AddShiti,
  SearchLeftCategory,
  DeleteShiti,
} from "@/request/storehouse";
import {
  GetShitiHengji,
  GetShitiManageList,
  Getchecker,
  GetYuyiOptions,
} from "@/request/check";
import { GetDaohang } from "@/request/schema";
import { GetSechemaList } from "@/request/mark";
import MainHeader from "@/components/main-header/main-header.vue";
import ShitiHengji from "../../components/hengji/shiti-hengji.vue";
@Component({
  components: {
    MainHeader,
    ShitiHengji,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  private ifShowTree: any = false;
  private currentNodeKey: any = "";
  private indent: any = 10;
  private props: any = {
    label: "name",
  };
  private data: any[] = [];
  private editData: any = {
    语义类型: {},
    上位概念: [],
  };
  private ifShowHengji: any = false;
  private hengjiId: any = "";
  private ifShowEdit: any = false;
  private types: any = [];
  private status: any = ["未校验", "已校验"];
  private jiaoyanyuan: any = [];
  private fenleiData: any = [];
  private suodingData: any = [];
  private yuyiNav: any = [];
  private defaultProps: any = {
    label: "域名称",
  };
  private yuyileixingList: any = [];
  private get kuList() {
    return this.$store.state.kuList;
  }
  private loadNode(node: any, resolve: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        _id: node.data ? node.data._id : "",
        语义id: this.editData["语义类型"]["域id"],
      },
    };
    GetLeftCategory(this, params, loading)
      .then((res: any) => {
        loading.close();
        resolve(res);
      })
      .catch(() => {
        loading.close();
        resolve([]);
      });
  }
  private handleYuyiNodeClick(data: any, node: any) {
    this.editData["语义类型"] = node.data;
    this.$forceUpdate();
    this.ifShowTree = false;
    this.getTypes();
  }
  private leixingChange(item: any) {
    this.editData["语义类型"] = {
      _id: item._id,
      域id: item._id,
      域名称: item.label,
      label: item.label,
      color: item.color,
    };
    this.$forceUpdate();
    this.ifShowTree = false;
    this.getTypes();
  }
  private handleNodeClick(data: any, node: any) {
    // this.editData["上位概念"] = node.data;
    // this.$forceUpdate();
  }
  private handleCheckChange(data: any, checked: any, indeterminate: any) {
    const checkData: any = (this.$refs.optTree as any).getCheckedNodes();
    this.editData["上位概念"] = checkData;
    this.$forceUpdate();
  }
  private cancelHengji() {
    this.ifShowHengji = false;
  }
  private forceUpdate() {
    this.$forceUpdate();
  }
  private getTypes() {
    const params: any = {
      params: {
        _id: "",
        语义id: this.editData["语义类型"]["域id"],
      },
    };
    GetLeftCategory(this, params).then((res: any) => {
      this.fenleiData = res;
      if (this.fenleiData.length > 0) {
        this.ifShowTree = true;
      }
    });
  }
  private addOne() {
    this.editData = {
      语义类型: {},
      上位概念: [],
    };
    this.fenleiData = [];
    this.getNav();
    this.ifShowEdit = true;
  }
  private addSure() {
    if (!this.editData["语义类型"] || !this.editData["语义类型"]["域id"]) {
      this.$message.warning("请选择语义类型");
      return;
    }
    if (!this.editData["名称"]) {
      this.$message.warning("请输入实体名称");
      return;
    }
    const params: any = {
      上位概念: this.editData["上位概念"],
      语义id: this.editData["语义类型"]["域id"],
      实体名称: this.editData["名称"],
    };
    AddShiti(this, params).then((res: any) => {
      this.ifShowEdit = false;
      this.getList();
    });
  }
  /**
   * @description 跳转用户编辑
   */
  private jumpCompose(item: any) {
    if (!this.getIsShowQuanxian("实体校验校验") || item["锁定"]) {
      return;
    }
    this.$store.commit("updateIfCompose", true);
    this.$router.push({
      path: "/main/check/bentijiaoyan/compose",
      query: {
        id: item.id,
        kind: item["语义类型"],
      },
    });
  }
  private deleteOne(item: any) {
    if (!this.getIsShowQuanxian("实体校验删除") || item["锁定"]) {
      return;
    }
    this.$confirm(
      "删除后该实体及下属的子内容将全部删除，您确定需要删除么？",
      "删除",
      {
        customClass: "commonConfirm",
      }
    )
      .then(() => {
        const params: any = {
          data: {
            id: item.id,
          },
        };
        DeleteShiti(this, params).then((res: any) => {
          this.$message.success("删除成功");
          this.getList();
        });
      })
      .catch(() => {
        console.log("取消");
      });
  }
  private openHengji(item: any) {
    // if (!this.getIsShowQuanxian("实体校验查看校验痕迹")) {
    //   return;
    // }
    this.hengjiId = item.id;
    this.ifShowHengji = true;
  }
  private getSuoding() {
    GetShitiManageList(this).then((data: any) => {
      this.suodingData = data;
    });
  }
  private ifSuoding(val: any) {
    let result: any = false;
    this.suodingData.forEach((ele: any) => {
      if (val === ele["概念"] && ele["状态"] === "已锁定") {
        result = true;
      }
    });
    return result;
  }
  private getChecker() {
    const params: any = {
      params: {
        kind: "原文校验",
      },
    };
    Getchecker(this, params).then((res: any) => {
      this.jiaoyanyuan = res;
    });
  }
  private getNav() {
    return new Promise((resolve, reject) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        params: {
          type: "语义类型",
        },
      };
      GetDaohang(this, params, loading).then((res: any) => {
        loading.close();
        this.yuyiNav = res;
        resolve(res);
      });
    });
  }
  private remoteMethod(e: any) {
    const params: any = {
      params: {
        search: e,
        is_all: true,
      },
    };
    GetYuyiOptions(this, params).then((res: any) => {
      this.types = res;
    });
  }
  private searchYuyiOptions(e: any) {
    const params: any = {
      params: {
        search: e,
        type: "语义类型",
      },
    };
    GetSechemaList(this, params).then((data: any) => {
      this.yuyileixingList = data;
    });
  }
  /**
   * @description
   */
  getList() {
    const params: any = {
      params: {
        search: this.filters.search,
        status: this.filters.status,
        table_name: this.filters.type["名称"],
        user_id: this.filters["校验员"].id,
        order_field: this.filters.sort_field,
        order_value: this.filters.sort_value,
        current_page: this.filters.current_page,
        page_num: this.filters.page_count,
      },
    };
    localStorage.setItem("bentijiaoyanliFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetShitiList(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.data = data.data;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 初始化
   */
  mounted() {
    this.filters.type = {};
    this.filters.status = "";
    this.filters.sort_field = "更新时间";
    this.filters["校验员"] = {};
    const d = localStorage.getItem("bentijiaoyanliFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.remoteMethod("");
    this.getList();
    this.getSuoding();
    this.getChecker();
  }
}
